<template>
  <div>
    <b-tabs content-class="mt-3">
      <!-- This tabs is for Category Management -->
      <b-tab title="Category Management" lazy>
        <br /><br />
        <div class="container form">
          <b-form inline>
            <h5>Category Management</h5>
            <b-button class="button" variant="primary">Add new</b-button>
            <b-form inline>
              <b-form-select
                :options="categoryOptions"
                v-model="form.parentCategory"
              ></b-form-select>
              <b-form-input
                id="name"
                type="text"
                v-model="form.category_name"
              ></b-form-input>
              <b-button variant="danger" class="form-btn" @click="saveCat()"
                >Save</b-button
              >
            </b-form>
          </b-form>
          <br /><br />

          <b-row>
            <b-col cols="12">
              <b-card no-body class="mb-4">
                <b-card-body class="p-3">
                  <h5 class="card-title mb-0">All Categories</h5>
                </b-card-body>
                <div class="p-3 d-flex align-items-center">
                  <b-form-group
                    label="Per page"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="9"
                    label-size="sm"
                    label-class="fw-medium"
                    class="mb-0"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                  <div class="ml-auto">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      class="my-0"
                    ></b-pagination>
                  </div>
                </div>

                <b-table
                  responsive
                  class="mb-0"
                  head-variant="light"
                  :items="categories"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template #cell(actions)>
            <b-button variant="light" class="sm" @click="deleteCat(data.categories.id)">
              <feather type="edit" class="feather-sm"></feather>
            </b-button>
            <b-button variant="danger" class="sm" style="margin:3px" @click="editCat(data.categories.id)">
              <feather type="trash" class="feather-sm" ></feather>
            </b-button>
         </template>
                </b-table><br><br>
                <div v-if="showUpdateCategory==true">
                <b-form inline>
            <h5>Update Category</h5>
              <br><br><br>
              <b-form-select
                :options="categoryOptions"
                v-model="form.parentCategory"
              ></b-form-select>
              <b-form-input
                id="name"
                type="text"
                v-model="form.category_name"
              ></b-form-input>
              <b-button variant="danger" class="form-btn" @click="saveCat()"
                >Save</b-button
              >
          </b-form>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-tab>

      <!-- This tabs is for Location-->
      <b-tab title="Location" lazy>
        <br /><br />
        <div class="container form" id="app">
          <b-form inline>
            <h5>Location</h5>
            <b-button class="button" variant="primary">Add new</b-button>
          </b-form>
          <b-form inline>
            <b-form-input
              id="name"
              type="text"
              v-model="form.location_name"
            ></b-form-input>
            <b-button variant="danger" class="form-btn" @click="saveloc()"
              >Save</b-button
            >
          </b-form>
          <b-row>
            <b-col cols="12">
              <b-card no-body class="mb-4">
                <b-card-body class="p-3">
                  <h5 class="card-title mb-0">All Locations</h5>
                </b-card-body>
                <div class="p-3 d-flex align-items-center">
                  <b-form-group
                    label="Per page"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="9"
                    label-size="sm"
                    label-class="fw-medium"
                    class="mb-0"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                  <div class="ml-auto">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      class="my-0"
                    ></b-pagination>
                  </div>
                </div>

                <b-table
                  responsive
                  class="mb-0"
                  head-variant="light"
                  :items="locations"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                 <template #cell(actions)>
            <b-button variant="light" class="sm" @click="editLocation(data.item.id)">
              <feather type="edit" class="feather-sm"></feather>
            </b-button>
            <b-button variant="danger" class="sm" style="margin:3px" @click="deleteLoc(data.item.id)">
              <feather type="trash" class="feather-sm" ></feather>
            </b-button>
         </template>
                </b-table>
                <br><br>
                <div v-if="showUpdateLocation==true">
                <b-form inline>
            <h5>Update Location</h5>
            <b-form-input
              id="name"
              type="text"
              v-model="form.location_name"
            ></b-form-input>
            <b-button variant="danger" class="form-btn" @click="saveloc()"
              >Save</b-button
            >
          </b-form>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-tab>

      <!--This tab is for Product Status-->
      <b-tab title="Product Status" lazy>
        <br /><br />
        <div class="container form">
          <b-form inline>
            <h5>Product Status</h5>
            <b-button class="button" variant="primary">Add new</b-button>
            <b-form inline>
              <b-form-input
                id="name"
                type="text"
                v-model="form.productStatus"
              ></b-form-input>
              <b-button
                variant="danger"
                class="form-btn"
                @click="saveStat()"
                >Save</b-button
              >
            </b-form>
          </b-form>
          <br /><br />

          <b-row>
            <b-col cols="12">
              <b-card no-body class="mb-4">
                <b-card-body class="p-3">
                  <h5 class="card-title mb-0">Product Statuses</h5>
                </b-card-body>
                <div class="p-3 d-flex align-items-center">
                  <b-form-group
                    label="Per page"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="9"
                    label-size="sm"
                    label-class="fw-medium"
                    class="mb-0"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                  <div class="ml-auto">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      class="my-0"
                    ></b-pagination>
                  </div>
                </div>

                <b-table
                  responsive
                  class="mb-0"
                  head-variant="light"
                  :items="productStatuses"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template #cell(actions)>
            <b-button variant="light" class="sm" @click="deleteStatus(data.productStatuses.id)">
              <feather type="edit" class="feather-sm"></feather>
            </b-button>
            <b-button variant="danger" class="sm" style="margin:3px" @click="editStatus()">
              <feather type="trash" class="feather-sm" ></feather>
            </b-button>
         </template>
                </b-table>
                <br><br>
                <div v-if="showUpdateStatus==true">
                <h3>Update</h3>
                <b-form inline>
            <h5>Product Status</h5>
            <b-button class="button" variant="primary">Add new</b-button>
            
              <b-form-input
                id="name"
                type="text"
              ></b-form-input>
              <b-button
                variant="danger"
                class="form-btn"
                >Save</b-button
              >
            </b-form>
            </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-tab>

            <!--This tab is for Product Status-->
      <b-tab title="User Types" lazy>
        <br /><br />
        <div class="container form">
          <b-form inline>
            <h5>User Type</h5>
            <b-button class="button" variant="primary">Add new</b-button>
            <b-form inline>
              <b-form-input
                id="name"
                type="text"
                v-model="form.userType"
              ></b-form-input>
              <b-button
                variant="danger"
                class="form-btn"
                @click="saveType()"
                >Save</b-button
              >
            </b-form>
          </b-form>
          <br /><br />

          <b-row>
            <b-col cols="12">
              <b-card no-body class="mb-4">
                <b-card-body class="p-3">
                  <h5 class="card-title mb-0">UserTypes</h5>
                </b-card-body>
                <div class="p-3 d-flex align-items-center">
                  <b-form-group
                    label="Per page"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="9"
                    label-size="sm"
                    label-class="fw-medium"
                    class="mb-0"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                  <div class="ml-auto">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      class="my-0"
                    ></b-pagination>
                  </div>
                </div>

                <b-table
                  responsive
                  class="mb-0"
                  head-variant="light"
                  :items="userTypes"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template #cell(id)="data"> #{{ data.item.id }} </template>
                  <template #cell(status)="data">
                    <span class="ml-2 fw-medium">{{
                      data.item.status.productStatus
                    }}</span>
                  </template>
                  <template #cell(action)>
                    <b-button variant="light">
                      <feather type="edit" class="feather-sm"></feather>
                    </b-button>
                  </template>
                </b-table>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "globalSettings",
  showUpdateCategory: false,
    showUpdateLocation: false,
    showUpdateStatus: false,
  data: () => ({
    page: {
      title: "PaginationTable",
    },
    
    form: {
      category_name: null,
      parentCategory: null,
      location_name: null,
      productStatus: null,
      userType: null
    },
    fields: ["id", "name", "created_at", "updated_at", "actions"],
    categories: [],
    categoryOptions: [{ text: "Select", value: 0 }],
    locations: [],
    productStatuses: [],
    userTypes: [],
    items: [],
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
  }),
  components: {},
  mounted() {
    this.initConfig();
    this.totalRows = this.items.length;
  },
  methods: {
    ...mapActions([
      "getCategories",
      "getLocations",
      "getProductStatuses",
      "getUserTypes",
      "setCategory",
      "setLocation",
      "setProductStatus",
      "deleteCategory",
      "deleteLocation",
      "deleteProductStatus",
      "setUserType"
    ]),
    initConfig() {
      this.getCategories().then((res) => {
        this.categories = res;
        this.categories.forEach((cat) => {
          this.categoryOptions.push({ text: cat.name, value: cat.id });
        });
      });
      this.getLocations().then((res) => {
        this.locations = res;
      });
      this.getProductStatuses().then((res) => {
        this.productStatuses = res;
      });
      this.getUserTypes().then((res) => {
        this.userTypes = res
      });
    },
    saveCat() {
      this.setCategory({
        parent_id: this.form.parentCategory,
        name: this.form.category_name,
      }).then((res) => {
        console.log("Added Successfully", res);
      });
    },
    saveloc() {
      this.setLocation({ name: this.form.location_name }).then((res) => {
        console.log("Added Successfully", res);
      });
    },
    saveStat() {
      this.setProductStatus({ name: this.form.productStatus }).then((res) => {
        console.log("Added Successfully", res);
      });
    },
    deleteCat(id) {
      this.deleteCategory({ id: id }).then(() => {
        this.initConfig();
      });
    },
    deleteLoc(id) {
      this.deleteLocation({ id: id }).then(() => {
        this.initConfig();
      });
    },
    deleteStatus(id) {
      this.deleteProductStatus({ id: id }).then(() => {
        this.initConfig();
      });
    },
    saveType() {
      this.setUserType({ name: this.form.userType }).then((res) => {
        console.log("Added Successfully", res);
      });
    },
    editCat(id) {
      this.showUpdateCategory=true;
      console.log(id)
    },
    editLoc(id) {
      this.showUpdateLocation=true;
      console.log(id)
    },
    editStatus(id) {
      this.showUpdateStatus=true;
      console.log(id)
    },
  },
};
</script>
<style>
.tab {
  tab-size: 2;
}
.body {
  text-align: center;
}
.input {
  max-width: 250px;
}
.button {
  margin-left: 400px;
}

.demo {
  font-family: sans-serif;
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 20px 30px;
  margin-top: 1em;
  margin-bottom: 40px;
  user-select: none;
  overflow-x: auto;
}
.input {
  display: block;
  padding: 10px, 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}
.form {
  max-width: 800px;
  margin-block-start: 0px;
  background: white;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}
</style>